<template>
  <v-card class="my-1 px-2">
    <v-row>
      <v-col cols="12" sm="4" md="2" xl="1">
        <v-text-field
          label="Stream ID"
          v-model.number="outlet.streamId"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" xl="2">
        <v-text-field label="Name" v-model="outlet.name" />
      </v-col>
      <v-col cols="12" sm="6" md="3" xl="2">
        <v-numeric-text-field-unit
          v-model="outlet.fraction"
          :settings="settings.fraction"
          @change="fractionChanged"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" xl="2">
        <v-numeric-text-field-unit
          v-model="outlet.massFlow"
          :settings="settings.massFlow"
          :readonly="true"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" xl="2">
        <v-switch
          label="Fixed Temperature"
          v-model="outlet.fixedTemperature"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="6" md="3" xl="3">
        <v-numeric-text-field-unit
          v-model="outlet.T"
          v-show="outlet.fixedTemperature"
          :settings="settings.temperature"
          :disabled="!outlet.fixedTemperature"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'OutletInputs',
  props: {
    value: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  components: {},
  data() {
    return {
      settings: {
        fraction: {
          label: 'Amount of total mass flow',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 1,
          baseUnit: '',
          unit: '%',
          precision: 3,
          units: ['%'],
          precisions: [3],
        },
        massFlow: {
          label: 'Actual mass flow',
          type: UnitConverter.types.FLOWRATE,
          min: null,
          max: null,
          baseUnit: 'kg/s',
          unit: 'kg/h',
          precision: 2,
          units: ['kg/h'],
          precisions: [2],
        },
        temperature: {
          label: 'Temperature',
          type: UnitConverter.types.TEMPERATURE,
          min: -223.15,
          max: null,
          baseUnit: 'K',
          unit: '°C',
          precision: 2,
          units: ['°C', 'K'],
          precisions: [2, 2],
        },
      },
    };
  },
  methods: {
    fractionChanged() {
      this.$emit('fractionChanged');
    },
  },
  computed: {
    outlet: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit('update', newValue);
      },
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style></style>
