<template>
  <div>
    <v-container fluid>
      <v-card class="px-4 py-4">
        <v-row>
          <v-col
            v-for="chamber in combustionChambers"
            :key="chamber.publicId"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <CombustionChamberCard
              :chamber="chamber"
              :iconColor="iconColor"
            />
          </v-col>
        </v-row>
        <v-row>
          <CombustionChamberPopup
            :inputChamber="emptyCombustionChamber()"
            :show="true"
            :readonly="false"
            iconColor="white"
            buttonClass="success"
            @updateCombustionChamber="addCombustionChamber"
          />
        </v-row>
      </v-card>
      <v-expansion-panels class="mt-4 py-4">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>Flow Chart</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PlantDiagram
              :projectDiagram="currentProject.diagram"
              :combustionChambers="combustionChambers"
              @updateProjectDiagram="updateProjectDiagram"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CombustionChamberCard from '@/components/combustionchambers/CombustionChamberCard';
import CombustionChamberPopup from '@/components/combustionchambers/CombustionChamberPopup';
import PlantDiagram from '@/components/combustionchambers/PlantDiagram';

export default {
  name: 'CombustionChambers',
  components: {
    CombustionChamberCard,
    CombustionChamberPopup,
    PlantDiagram,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('combustionChambers', [
      'combustionChambers',
      'emptyCombustionChamber',
    ]),
    ...mapGetters('projects', ['currentProject']),
    ...mapGetters(['iconColor']),
  },
  methods: {
    ...mapActions('combustionChambers', ['addCombustionChamber']),
    ...mapActions('projects', ['updateProjectDiagram']),
  },
  mounted() {},
};
</script>

<style></style>
