var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"field",staticClass:"scrollXY",attrs:{"id":"svg-diagram-combustion-chamber-show-area"}},[_c('svg',{attrs:{"id":"svg-diagram-combustion-chamber","width":_vm.fluid ? '100%' : _vm.width,"height":_vm.fluid ? '100%' : _vm.height,"viewBox":_vm.viewBoxDiagram,"xmlns":"http://www.w3.org/2000/svg"}},[_c('defs',[_c('pattern',{attrs:{"id":_vm.smallGridID,"width":100 * _vm.measuringUnit,"height":100 * _vm.measuringUnit,"patternUnits":"userSpaceOnUse"}}),_c('pattern',{attrs:{"id":_vm.gridID,"width":1000 * _vm.measuringUnit,"height":1000 * _vm.measuringUnit,"patternUnits":"userSpaceOnUse"}},[_c('rect',{attrs:{"width":1000 * _vm.measuringUnit,"height":1000 * _vm.measuringUnit,"fill":`url('#${_vm.smallGridID}')`}}),_c('path',{attrs:{"d":'M ' +
              1000 * _vm.measuringUnit +
              ' 0 L 0 0 0 ' +
              1000 * _vm.measuringUnit,"fill":"none","stroke":"gray","stroke-width":"1"}})])]),_c('g',{attrs:{"transform":_vm.scaleStr}},[_c('rect',{attrs:{"x":"0","y":"0","width":_vm.width,"height":_vm.height,"fill":_vm.showGrid ? `url('#${_vm.gridID}')` : _vm.background},on:{"click":_vm.reset}}),(_vm.background === '#ffffff')?_c('g',[_c('text',{attrs:{"x":1100 * _vm.measuringUnit,"y":20,"fill":"#000000","font-family":"Meiryo UI, sans-serif"}},[_vm._v(" 1 m ")]),_c('line',{staticStyle:{"stroke":"rgb(0, 0, 0)","stroke-width":"2"},attrs:{"x1":0,"y1":0,"x2":0,"y2":100 * _vm.measuringUnit}}),_c('path',{staticStyle:{"stroke":"rgb(0, 0, 0)","stroke-width":"2"},attrs:{"d":'M 0,' +
              100 * _vm.measuringUnit * 0.5 +
              ' h' +
              1000 * _vm.measuringUnit}}),_c('line',{staticStyle:{"stroke":"rgb(0, 0, 0)","stroke-width":"2"},attrs:{"x1":1000 * _vm.measuringUnit,"y1":0,"x2":1000 * _vm.measuringUnit,"y2":100 * _vm.measuringUnit}})]):_c('g',[_c('text',{attrs:{"x":1100 * _vm.measuringUnit,"y":20,"fill":"#ffffff","font-family":"Meiryo UI, sans-serif"}},[_vm._v(" 1 m ")]),_c('line',{staticStyle:{"stroke":"rgb(255, 255, 255)","stroke-width":"2"},attrs:{"x1":0,"y1":0,"x2":0,"y2":100 * _vm.measuringUnit}}),_c('path',{staticStyle:{"stroke":"rgb(255, 255, 255)","stroke-width":"2"},attrs:{"d":'M 0,' +
              100 * _vm.measuringUnit * 0.5 +
              ' h' +
              1000 * _vm.measuringUnit}}),_c('line',{staticStyle:{"stroke":"rgb(255, 255, 255)","stroke-width":"2"},attrs:{"x1":1000 * _vm.measuringUnit,"y1":0,"x2":1000 * _vm.measuringUnit,"y2":100 * _vm.measuringUnit}})]),_vm._l((_vm.nodeList),function(item){return _c('NodeXYZ',{key:item.id,attrs:{"node":item,"selected":item.id === _vm.selectedNode,"createLinkMode":_vm.createLinkMode,"editable":_vm.editable,"labels":_vm.labels,"rWidth":_vm.rect().rWidth,"rHeight":_vm.rect().rHeight,"scale":_vm.scale,"screenHeight":_vm.height,"screenWidth":_vm.width},on:{"editNode":_vm.editNode,"select":_vm.selectNode,"copy":_vm.copyNode,"updateLocation":_vm.updateNodeLocation,"toggleSelect":_vm.toggleSrcSelect,"commitDest":_vm.commitDest,"remove":_vm.removeNode}})})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }