<template>
  <g>
    <linearGradient
      :id="gradientHorizontalID"
      x1="0%"
      y1="0%"
      x2="0%"
      y2="100%"
    >
      <stop offset="0%" style="stop-color:#303030" />
      <stop offset="60%" style="stop-color:#e6e6e6" />
      <stop offset="100%" style="stop-color:#666" />
    </linearGradient>
    <!--<linearGradient v-if="
        node.objectType === 'burner' ||
          node.objectType === 'burner-constricted'
      "
                    :id="gradientHorizontalTemperatureID">
      <stop v-for="(stop, idx) in node.stops"
            :key="idx"
            :offset="stop.x"
            :stop-color="getColor(stop.T)" />
    </linearGradient>-->
    <!--YZ-Direction-->
    <g>
      <!-- horizontal -->
      <g v-if="node.orientation === 'horizontal'">
        <g v-if="node.shape === 'cylindrical'">
          <ellipse
            class="grab"
            :cx="
              x +
                node.width / 2 +
                (screenWidth -
                  node.length -
                  node.width * (1.5 + 1 / 3)) /
                  2
            "
            :cy="
              y + node.height / 2 + (screenHeight - node.height) / 2
            "
            :width="node.width"
            :height="node.height"
            :rx="node.width / 2"
            :ry="node.height / 2"
            fill="#e6e6e6"
            stroke="#000000"
            stroke-width="1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
        <g v-if="node.shape === 'rectangular'">
          <rect
            class="grab"
            :x="
              x +
                (screenWidth -
                  node.length -
                  node.width * (1.5 + 1 / 3)) /
                  2
            "
            :y="y + (screenHeight - node.height) / 2"
            :width="node.width"
            :height="node.height"
            rx="5"
            ry="3"
            fill="#e6e6e6"
            stroke="#000000"
            stroke-width="1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
      </g>
      <!-- vertical -->
      <g v-else-if="node.orientation === 'vertical'">
        <g v-if="node.shape === 'cylindrical'">
          <ellipse
            class="grab"
            :cx="
              x +
                node.width / 2 +
                (screenWidth - node.height - node.width * 1.5) / 2
            "
            :cy="
              y + node.height / 2 + (screenHeight - node.height) / 2
            "
            :width="node.width"
            :height="node.height"
            :rx="node.width / 2"
            :ry="node.height / 2"
            fill="#e6e6e6"
            stroke="#000000"
            stroke-width="1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
        <g v-if="node.shape === 'rectangular'">
          <rect
            class="grab"
            :x="
              x + (screenWidth - node.height - node.width * 1.5) / 2
            "
            :y="y + (screenHeight - node.height) / 2"
            :width="node.width"
            :height="node.height"
            rx="5"
            ry="3"
            fill="#e6e6e6"
            stroke="#000000"
            stroke-width="1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
      </g>
    </g>
    <!-- XY-direction -->
    <g>
      <!-- burner , burner-constricted -->
      <g
        v-if="
          node.objectType === 'burner' ||
            node.objectType === 'burner-constricted'
        "
      >
        <!-- Horizontal -->
        <g v-if="node.orientation === 'horizontal'">
          <!-- chamber horizontal -->
          <g>
            <rect
              v-if="
                node.objectType === 'burner' ||
                  node.objectType === 'burner-constricted'
              "
              class="grab"
              :x="
                x +
                  (screenWidth -
                    node.length -
                    node.width * (1.5 + 1 / 3)) /
                    2 +
                  node.width * 1.5
              "
              :y="
                y +
                  (node.height * 1) / 3 +
                  (screenHeight - node.height) / 2
              "
              :width="(node.height * 1) / 3"
              :height="(node.height * 1) / 3"
              rx="5"
              ry="3"
              fill="#e6e6e6"
              stroke="#000000"
              stroke-width="1"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
            <rect
              v-if="
                node.objectType === 'burner' ||
                  node.objectType === 'burner-constricted'
              "
              class="grab"
              :x="
                x +
                  (screenWidth -
                    node.length -
                    node.width * (1.5 + 1 / 3)) /
                    2 +
                  node.width * 1.5 +
                  (node.height * 1) / 3
              "
              :y="y + (screenHeight - node.height) / 2"
              :width="node.length"
              :height="node.height"
              rx="5"
              ry="3"
              :fill="`url('#${gradientHorizontalID}')`"
              stroke="#000000"
              stroke-width="2"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
            <rect
              v-if="node.objectType === 'burner-constricted'"
              class="grab"
              :x="
                x +
                  (screenWidth -
                    node.length -
                    node.width * (1.5 + 1 / 3)) /
                    2 +
                  node.width * 1.5 +
                  (node.height * 1) / 3 +
                  node.length * 0.5
              "
              :y="y + (screenHeight - node.height) / 2"
              :width="node.height * 0.1"
              :height="(node.height * 1) / 3"
              style="fill:#000000;"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
            <rect
              v-if="node.objectType === 'burner-constricted'"
              class="grab"
              :x="
                x +
                  (screenWidth -
                    node.length -
                    node.width * (1.5 + 1 / 3)) /
                    2 +
                  node.width * 1.5 +
                  (node.height * 1) / 3 +
                  node.length * 0.5
              "
              :y="
                y +
                  (node.height * 2) / 3 +
                  (screenHeight - node.height) / 2
              "
              :width="node.height * 0.1"
              :height="(node.height * 1) / 3"
              style="fill:#000000;stroke:#000000;stroke-width:1"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
          </g>
          <!-- Flame horizontal -->
          <!--<g>
            <path v-if="
                node.objectType === 'burner' ||
                  node.objectType === 'burner-constricted'
              "
                  style="fill:#ff0000;fill-opacity:1;stroke:none"
                  class="grab"
                  :x="
                x +
                  (screenWidth -
                    node.length -
                    node.width * (1.5 + 1 / 3)) /
                    2 +
                  node.width * 1.5
              "
                  :y="y + (screenHeight - node.height) / 2"
                  :d="
                'm' +
                  (x +
                    (screenWidth -
                      node.length -
                      node.width * (1.5 + 1 / 3)) /
                      2 +
                    node.width * 1.5 +
                    (node.height * 1) / 3 )+
                  ',' +
                  (y +
                    node.height * 0.5 +
                    (screenHeight - node.height) / 2) +
                  ' c '+
                  '0,0 '+
                  + 0 + ',' + node.width/2 + ' '+
                  + (node.length * 2) / 3 +',0 '+
                    '0,0 '+
                    + -node.length*2/3 + ',' + -node.width/2 + ' '+
                  + -node.length * 2/3 +',0 '+
                  ' z'
              "
                  @touchstart="mousedown"
                  @mousedown="mousedown"
                  @mousemove="mousemove"
                  @touchmove="mousemove"
                  @mouseup="mouseup"
                  @touchend="mouseup" />
            <path v-if="
      node.objectType === 'burner' ||
        node.objectType === 'burner-constricted'
    "
                  style="fill:#ff6600;fill-opacity:1;stroke:none"
                  class="grab"
                  :x="
      x +
        (screenWidth -
          node.length -
          node.width * (1.5 + 1 / 3)) /
          2 +
        node.width * 1.5
    "
                  :y="y + (screenHeight - node.height) / 2"
                  :d="
      'm' +
        (x +
          (screenWidth -
            node.length -
            node.width * (1.5 + 1 / 3)) /
            2 +
          node.width * 1.5 +
          (node.height * 1) / 3 ) +
        ',' +
        (y +
          node.height * 0.5 +
          (screenHeight - node.height) / 2) +
       ' c '+
                  '0,0 '+
                  + 0 + ',' + node.width/2*0.8 + ' '+
                  + (node.length * 2) / 3*0.8 +',0 '+
                    '0,0 '+
                    + -node.length*2/3*0.8 + ',' + -node.width/2*0.8 + ' '+
                  + -(node.length * 2) / 3*0.8 +',0 '+
                  ' z'
    "
                  @touchstart="mousedown"
                  @mousedown="mousedown"
                  @mousemove="mousemove"
                  @touchmove="mousemove"
                  @mouseup="mouseup"
                  @touchend="mouseup" />

            <path v-if="
      node.objectType === 'burner' ||
        node.objectType === 'burner-constricted'
    "
                  style="fill:#ffff00;stroke:none;stroke-opacity:1"
                  class="grab"
                  :x="
      x +
        (screenWidth -
          node.length -
          node.width * (1.5 + 1 / 3)) /
          2 +
        node.width * 1.5
    "
                  :y="y + (screenHeight - node.height) / 2"
                  :d="
      'm' +
        (x +
          (screenWidth -
            node.length -
            node.width * (1.5 + 1 / 3)) /
            2 +
          node.width * 1.5 +
          (node.height * 1) / 3 ) +
        ',' +
        (y +
          node.height * 0.5 +
          (screenHeight - node.height) / 2) +
        ' c '+
                  '0,0 '+
                  + 0 + ',' + node.width/2*0.6 + ' '+
                  + (node.length * 2) / 3*0.6 +',0 '+
                    '0,0 '+
                    + -node.length*2/3*0.6 + ',' + -node.width/2*0.6 + ' '+
                  + -(node.length * 2) / 3*0.6 +',0 '+
                  ' z'
    "
                  @touchstart="mousedown"
                  @mousedown="mousedown"
                  @mousemove="mousemove"
                  @touchmove="mousemove"
                  @mouseup="mouseup"
                  @touchend="mouseup" />


          </g>-->
        </g>
        <!-- Vertical -->
        <g v-else-if="node.orientation === 'vertical'">
          <!--chamber vertical-->
          <g>
            <linearGradient
              :id="gradientVerticalID"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" style="stop-color:#303030" />
              <stop offset="60%" style="stop-color:#e6e6e6" />
              <stop offset="100%" style="stop-color:#666" />
            </linearGradient>
            <!--<linearGradient v-if="
                node.objectType === 'burner' ||
                  node.objectType === 'burner-constricted'
              "
                            :id="gradientVerticalTemperatureID"
                            gradientTransform="rotate(90)">
              <stop v-for="(stop, idx) in node.stops"
                    :key="idx"
                    :offset="stop.x"
                    :stop-color="getColor(stop.T)" />
            </linearGradient>-->
            <rect
              v-if="
                node.objectType === 'burner' ||
                  node.objectType === 'burner-constricted'
              "
              class="grab"
              :x="
                x +
                  (node.height * 1) / 3 +
                  (screenWidth - node.height - node.width * 1.5) / 2 +
                  node.width * 1.5
              "
              :y="
                y +
                  (screenHeight -
                    node.length -
                    (node.height * 1) / 3) /
                    2
              "
              :width="(node.height * 1) / 3"
              :height="(node.height * 1) / 3"
              rx="5"
              ry="3"
              fill="#e6e6e6"
              stroke="#000000"
              stroke-width="1"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
            <rect
              v-if="
                node.objectType === 'burner' ||
                  node.objectType === 'burner-constricted'
              "
              class="grab"
              :x="
                x +
                  (screenWidth - node.height - node.width * 1.5) / 2 +
                  node.width * 1.5
              "
              :y="
                y +
                  (screenHeight -
                    node.length -
                    (node.height * 1) / 3) /
                    2 +
                  (node.height * 1) / 3
              "
              :width="node.height"
              :height="node.length"
              rx="5"
              ry="3"
              :fill="`url('#${gradientVerticalID}')`"
              stroke="#000000"
              stroke-width="2"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
            <rect
              v-if="node.objectType === 'burner-constricted'"
              class="grab"
              :x="
                x +
                  (screenWidth - node.height - node.width * 1.5) / 2 +
                  node.width * 1.5
              "
              :y="
                y +
                  (screenHeight -
                    node.length -
                    (node.height * 1) / 3) /
                    2 +
                  (node.height * 1) / 3 +
                  node.length * 0.65
              "
              :width="(node.height * 1) / 3"
              :height="node.height * 0.1"
              style="fill:#000000;"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
            <rect
              v-if="node.objectType === 'burner-constricted'"
              class="grab"
              :x="
                x +
                  (node.height * 2) / 3 +
                  (screenWidth - node.height - node.width * 1.5) / 2 +
                  node.width * 1.5
              "
              :y="
                y +
                  (screenHeight -
                    node.length -
                    (node.height * 1) / 3) /
                    2 +
                  (node.height * 1) / 3 +
                  node.length * 0.65
              "
              :width="node.height * 0.1"
              :height="(node.height * 1) / 3"
              style="fill:#000000;stroke:#000000;stroke-width:1"
              @touchstart="mousedown"
              @mousedown="mousedown"
              @mousemove="mousemove"
              @touchmove="mousemove"
              @mouseup="mouseup"
              @touchend="mouseup"
            />
          </g>
          <!--Flame vertical-->
          <!--<g>
            <g>
              <path v-if="
                  node.objectType === 'burner' ||
                    node.objectType === 'burner-constricted'
                "
                    style="fill:#ff0000;fill-opacity:1;stroke:none"
                    class="grab"
                    :x="
                  x +
                    (screenWidth - node.height - node.width * 1.5) /
                      2 +
                    node.height * 0.5 +
                    node.width * 1.5
                "
                    :y="
                  y +
                    (screenHeight -
                      node.length -
                      (node.height * 1) / 3) /
                      2
                "
                    :d="
                  'm' +
                    (x +
                      node.height * 0.5 +
                      node.width * 1.5 +
                      (screenWidth - node.height - node.width * 1.5) /
                        2) +
                    ',' +
                    (y +
                      (screenHeight -
                        node.length -
                        (node.height * 1) / 3) /
                        2 +
                      (node.height * 1) / 3) +
                    ' c '+
                  '0,0 '+
                  + node.width/2 + ',' + 0 + ' '+
                  +0 +',' + (node.length * 2) / 3+ ' '+
                    '0,0 '+
                    + -node.width/2 + ',' + -node.length*2/3 + ' '+
                  + 0 +','+ -node.length * 2/3 + ' '+
                  ' z'
                "
                    @touchstart="mousedown"
                    @mousedown="mousedown"
                    @mousemove="mousemove"
                    @touchmove="mousemove"
                    @mouseup="mouseup"
                    @touchend="mouseup" />
              <path v-if="
      node.objectType === 'burner' ||
        node.objectType === 'burner-constricted'
    "
        style="fill:#ff6600;fill-opacity:1;stroke:none"
        class="grab"
        :x="
      x +
        (screenWidth - node.height - node.width * 1.5) /
          2 +
        node.height * 0.5 +
        node.width * 1.5
    "
        :y="
      y +
        (screenHeight -
          node.length -
          (node.height * 1) / 3) /
          2
    "
        :d="
      'm' +
        (x +
          node.height * 0.5 +
          node.width * 1.5 +
          (screenWidth - node.height - node.width * 1.5) /
            2) +
        ',' +
        (y +
          (screenHeight -
            node.length -
            (node.height * 1) / 3) /
            2 +
          (node.height * 1) / 3 ) +
         ' c '+
                  '0,0 '+
                  + node.width/2*0.8 + ',' + 0 + ' '+
                  +0 +',' + (node.length * 2) / 3*0.8+ ' '+
                    '0,0 '+
                    + -node.width/2 *0.8+ ',' + -node.length*2/3*0.8 + ' '+
                  + 0 +','+ -node.length * 2/3 *0.8+ ' '+
                  ' z'
    "
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup" />
  <path v-if="
      node.objectType === 'burner' ||
        node.objectType === 'burner-constricted'
    "
        style="fill:#ffff00;stroke:none;stroke-opacity:1"
        class="grab"
        :x="
      x +
        (screenWidth - node.height - node.width * 1.5) /
          2 +
        node.height * 0.5 +
        node.width * 1.5
    "
        :y="
      y +
        (screenHeight -
          node.length -
          (node.height * 1) / 3) /
          2
    "
        :d="
      'm' +
        (x +
          node.height * 0.5 +
          node.width * 1.5 +
          (screenWidth - node.height - node.width * 1.5) /
            2) +
        ',' +
        (y +
          (screenHeight -
            node.length -
            (node.height * 1) / 3) /
            2 +
          (node.height * 1) / 3) +
         ' c '+
                  '0,0 '+
                  + node.width/2*0.6 + ',' + 0 + ' '+
                  +0 +',' + (node.length * 2) / 3*0.6+ ' '+
                    '0,0 '+
                    + -node.width/2 *0.6+ ',' + -node.length*2/3*0.6 + ' '+
                  + 0 +','+ -node.length * 2/3 *0.6+ ' '+
                  ' z'
    "
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup" />
            </g>
          </g>-->
        </g>
      </g>
    </g>
  </g>
</template>
<script>
import { nodeMixin } from '@/mixins/nodeMixin';
export default {
  mixins: [nodeMixin],
};
</script>
<style lang="scss" scoped>
.shadow {
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  -moz-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
}

.button {
  cursor: pointer;
}
</style>
