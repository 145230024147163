<template>
  <v-card>
    <v-container fluid>
      <v-data-iterator
        :items="iteratorMixtures"
        :sort-by="sortByCamelCase"
        :sort-desc="sortDesc"
        hide-default-footer
        :items-per-page="100"
      >
        <template v-slot:header>
          <v-toolbar>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-switch
                class="mt-4"
                v-show="switchForUnusedMixtures"
                label="Show unused Mixtures only"
                v-model="showOnlyUnusedMixtures"
              >
              </v-switch>
              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-select
                  v-model="sortBy"
                  flat
                  hide-details
                  :items="sortByKeys"
                  label="Sort by"
                />
              </v-col>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn small depressed :value="false">
                  <v-icon>mdi-sort-descending</v-icon>
                </v-btn>
                <v-btn small depressed :value="true">
                  <v-icon>mdi-sort-ascending</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.publicId"
              cols="12"
              xs="12"
              sm="6"
              md="4"
              lg="2"
            >
              <MixtureCard
                :mixture="item"
                :mixtures="mixtures"
                :sortBy="sortBy"
                :readonly="readonly"
                :disabled="
                  disabledMixtures.some(
                    s => s.publicId === item.publicId,
                  )
                "
                :deletable="deletable"
                :checked="checked.some(e => e === item.publicId)"
                :iconColor="iconColor"
                @deleteMixture="deleteMixture"
                @changeChecked="changeChecked"
                @disabledAction="disabledAction"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import MixtureCard from '@/components/mixtures/MixtureCard';

export default {
  name: 'MixtureIterator',
  props: {
    mixtures: { type: Array, required: true },
    disabledMixtures: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    deletable: { type: Boolean, default: true },
    checked: { type: Array, required: true },
    iconColor: { type: String, default: 'white' },
    switchForUnusedMixtures: { type: Boolean, default: false },
  },
  components: { MixtureCard },
  data() {
    return {
      filter: {},
      sortDesc: false,
      sortBy: 'Stream ID',
      sortByKeys: [
        'Stream ID',
        'Name',
        'Phase',
        'Stream Type',
        'Mass Flow',
        'Temperature',
      ],
      showOnlyUnusedMixtures: false,
    };
  },
  methods: {
    deleteMixture(mixtureToDelete) {
      this.$emit('deleteMixture', mixtureToDelete);
    },
    changeChecked(publicId) {
      this.$emit('changeChecked', publicId);
    },
    disabledAction() {
      this.$emit('disabledAction');
    },
  },
  computed: {
    sortByCamelCase() {
      return _.camelCase(this.sortBy);
    },
    iteratorMixtures() {
      // console.log(this.showOnlyUnusedMixtures);
      var iteratorMixtures = this.showOnlyUnusedMixtures
        ? this.mixtures.filter(mix => {
            var isNotInDisabledMixtures = this.disabledMixtures.every(
              disMix => disMix.publicId !== mix.publicId,
            );
            // console.log(`(${mix.streamId}) ${mix.name}: ${isNotInDisabledMixtures}`);
            return isNotInDisabledMixtures;
          })
        : this.mixtures;
      // console.log('Disabled Mixtures:')
      // console.log(this.disabledMixtures.map(mix => `(${mix.streamId})${mix.name}`));
      // console.log('Iterator Mixtures:')
      // console.log(iteratorMixtures.map(mix => `(${mix.streamId})${mix.name}`));
      return iteratorMixtures;
    },
  },
  mounted() {},
};
</script>

<style></style>
