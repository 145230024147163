var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_c('linearGradient',{attrs:{"id":_vm.gradientHorizontalID,"x1":"0%","y1":"0%","x2":"0%","y2":"100%"}},[_c('stop',{staticStyle:{"stop-color":"#303030"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"#e6e6e6"},attrs:{"offset":"60%"}}),_c('stop',{staticStyle:{"stop-color":"#666"},attrs:{"offset":"100%"}})],1),_c('g',[(_vm.node.orientation === 'horizontal')?_c('g',[(_vm.node.shape === 'cylindrical')?_c('g',[_c('ellipse',{staticClass:"grab",attrs:{"cx":_vm.x +
              _vm.node.width / 2 +
              (_vm.screenWidth -
                _vm.node.length -
                _vm.node.width * (1.5 + 1 / 3)) /
                2,"cy":_vm.y + _vm.node.height / 2 + (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.width,"height":_vm.node.height,"rx":_vm.node.width / 2,"ry":_vm.node.height / 2,"fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}})]):_vm._e(),(_vm.node.shape === 'rectangular')?_c('g',[_c('rect',{staticClass:"grab",attrs:{"x":_vm.x +
              (_vm.screenWidth -
                _vm.node.length -
                _vm.node.width * (1.5 + 1 / 3)) /
                2,"y":_vm.y + (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.width,"height":_vm.node.height,"rx":"5","ry":"3","fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}})]):_vm._e()]):(_vm.node.orientation === 'vertical')?_c('g',[(_vm.node.shape === 'cylindrical')?_c('g',[_c('ellipse',{staticClass:"grab",attrs:{"cx":_vm.x +
              _vm.node.width / 2 +
              (_vm.screenWidth - _vm.node.height - _vm.node.width * 1.5) / 2,"cy":_vm.y + _vm.node.height / 2 + (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.width,"height":_vm.node.height,"rx":_vm.node.width / 2,"ry":_vm.node.height / 2,"fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}})]):_vm._e(),(_vm.node.shape === 'rectangular')?_c('g',[_c('rect',{staticClass:"grab",attrs:{"x":_vm.x + (_vm.screenWidth - _vm.node.height - _vm.node.width * 1.5) / 2,"y":_vm.y + (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.width,"height":_vm.node.height,"rx":"5","ry":"3","fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}})]):_vm._e()]):_vm._e()]),_c('g',[(
        _vm.node.objectType === 'burner' ||
          _vm.node.objectType === 'burner-constricted'
      )?_c('g',[(_vm.node.orientation === 'horizontal')?_c('g',[_c('g',[(
              _vm.node.objectType === 'burner' ||
                _vm.node.objectType === 'burner-constricted'
            )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x +
                (_vm.screenWidth -
                  _vm.node.length -
                  _vm.node.width * (1.5 + 1 / 3)) /
                  2 +
                _vm.node.width * 1.5,"y":_vm.y +
                (_vm.node.height * 1) / 3 +
                (_vm.screenHeight - _vm.node.height) / 2,"width":(_vm.node.height * 1) / 3,"height":(_vm.node.height * 1) / 3,"rx":"5","ry":"3","fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
              _vm.node.objectType === 'burner' ||
                _vm.node.objectType === 'burner-constricted'
            )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x +
                (_vm.screenWidth -
                  _vm.node.length -
                  _vm.node.width * (1.5 + 1 / 3)) /
                  2 +
                _vm.node.width * 1.5 +
                (_vm.node.height * 1) / 3,"y":_vm.y + (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.length,"height":_vm.node.height,"rx":"5","ry":"3","fill":`url('#${_vm.gradientHorizontalID}')`,"stroke":"#000000","stroke-width":"2"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000"},attrs:{"x":_vm.x +
                (_vm.screenWidth -
                  _vm.node.length -
                  _vm.node.width * (1.5 + 1 / 3)) /
                  2 +
                _vm.node.width * 1.5 +
                (_vm.node.height * 1) / 3 +
                _vm.node.length * 0.5,"y":_vm.y + (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.height * 0.1,"height":(_vm.node.height * 1) / 3},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000","stroke":"#000000","stroke-width":"1"},attrs:{"x":_vm.x +
                (_vm.screenWidth -
                  _vm.node.length -
                  _vm.node.width * (1.5 + 1 / 3)) /
                  2 +
                _vm.node.width * 1.5 +
                (_vm.node.height * 1) / 3 +
                _vm.node.length * 0.5,"y":_vm.y +
                (_vm.node.height * 2) / 3 +
                (_vm.screenHeight - _vm.node.height) / 2,"width":_vm.node.height * 0.1,"height":(_vm.node.height * 1) / 3},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()])]):(_vm.node.orientation === 'vertical')?_c('g',[_c('g',[_c('linearGradient',{attrs:{"id":_vm.gradientVerticalID,"x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{staticStyle:{"stop-color":"#303030"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"#e6e6e6"},attrs:{"offset":"60%"}}),_c('stop',{staticStyle:{"stop-color":"#666"},attrs:{"offset":"100%"}})],1),(
              _vm.node.objectType === 'burner' ||
                _vm.node.objectType === 'burner-constricted'
            )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x +
                (_vm.node.height * 1) / 3 +
                (_vm.screenWidth - _vm.node.height - _vm.node.width * 1.5) / 2 +
                _vm.node.width * 1.5,"y":_vm.y +
                (_vm.screenHeight -
                  _vm.node.length -
                  (_vm.node.height * 1) / 3) /
                  2,"width":(_vm.node.height * 1) / 3,"height":(_vm.node.height * 1) / 3,"rx":"5","ry":"3","fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
              _vm.node.objectType === 'burner' ||
                _vm.node.objectType === 'burner-constricted'
            )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x +
                (_vm.screenWidth - _vm.node.height - _vm.node.width * 1.5) / 2 +
                _vm.node.width * 1.5,"y":_vm.y +
                (_vm.screenHeight -
                  _vm.node.length -
                  (_vm.node.height * 1) / 3) /
                  2 +
                (_vm.node.height * 1) / 3,"width":_vm.node.height,"height":_vm.node.length,"rx":"5","ry":"3","fill":`url('#${_vm.gradientVerticalID}')`,"stroke":"#000000","stroke-width":"2"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000"},attrs:{"x":_vm.x +
                (_vm.screenWidth - _vm.node.height - _vm.node.width * 1.5) / 2 +
                _vm.node.width * 1.5,"y":_vm.y +
                (_vm.screenHeight -
                  _vm.node.length -
                  (_vm.node.height * 1) / 3) /
                  2 +
                (_vm.node.height * 1) / 3 +
                _vm.node.length * 0.65,"width":(_vm.node.height * 1) / 3,"height":_vm.node.height * 0.1},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000","stroke":"#000000","stroke-width":"1"},attrs:{"x":_vm.x +
                (_vm.node.height * 2) / 3 +
                (_vm.screenWidth - _vm.node.height - _vm.node.width * 1.5) / 2 +
                _vm.node.width * 1.5,"y":_vm.y +
                (_vm.screenHeight -
                  _vm.node.length -
                  (_vm.node.height * 1) / 3) /
                  2 +
                (_vm.node.height * 1) / 3 +
                _vm.node.length * 0.65,"width":_vm.node.height * 0.1,"height":(_vm.node.height * 1) / 3},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()],1)]):_vm._e()]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }