<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? 20 : 2">
      <v-card-title class="subheading font-weight-bold">
        {{ chamber.name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list dense color="transparent">
          <v-list-item>
            <v-list-item-content>
              Length:
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ length }} m
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="chamber.shape === 'cylindrical'">
            <v-list-item-content>
              Diameter:
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ diameter }} m
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="chamber.shape === 'rectangular'">
            <v-list-item-content>
              Width:
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ width }} m
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="chamber.shape === 'rectangular'">
            <v-list-item-content>
              Height:
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ height }} m
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              &lambda;
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ lambda }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-show="chamber.setpointActive">
            <v-list-item-content>
              &lambda; with setpoint
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ lambdaWithSetpoint }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CombustionChamberPopup
          :inputChamber="chamber"
          :show="hover"
          :iconColor="iconColor"
          @updateCombustionChamber="updateCombustionChamber"
        />
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          text
          color="transparent"
          @click="deleteCombustionChamber(chamber.publicId)"
        >
          <v-icon :color="hover ? iconColor : 'transparent'">
            mdi-delete
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import CombustionChamberPopup from './CombustionChamberPopup';

export default {
  name: 'CombustionChamberCard',
  props: {
    chamber: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
  },
  components: { CombustionChamberPopup },
  data() {
    return {};
  },
  methods: {
    ...mapActions('combustionChambers', [
      'deleteCombustionChamber',
      'updateCombustionChamber',
    ]),
  },
  computed: {
    length() {
      return _.round(this.chamber.length, 2);
    },
    diameter() {
      return _.round(this.chamber.diameter, 2);
    },
    height() {
      return _.round(this.chamber.height, 2);
    },
    width() {
      return _.round(this.chamber.width, 2);
    },
    lambda() {
      return isNaN(this.chamber.lambda)
        ? ''
        : _.round(this.chamber.lambda, 4);
    },
    lambdaWithSetpoint() {
      return isNaN(this.chamber.lambdaWithSetpoint)
        ? ''
        : _.round(this.chamber.lambdaWithSetpoint, 4);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}
</style>
