<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
          :class="buttonClass"
          @click="openDialog"
          depressed
          text
        >
          <v-icon :color="show ? iconColor : 'transparent'">
            {{ formIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ formTitle }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> {{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="updateCombustionChamber"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Save Changes </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form class="px-3" ref="form">
            <v-expansion-panels v-model="panels" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>General</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                        label="Name"
                        v-model="chamber.name"
                        :rules="[requiredRule]"
                      />
                      <v-numeric-text-field-unit
                        v-model="chamber.p"
                        :settings="settings.pressure"
                        :rules="[requiredRule]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-select
                        label="Shape"
                        v-model="chamber.shape"
                        :items="shapes"
                        :rules="[requiredRule]"
                        @change="changeShape"
                      />
                      <v-select
                        label="Orientation"
                        v-model="chamber.orientation"
                        :items="orientations"
                        :rules="[requiredRule]"
                        @change="updateFlowDirection"
                      />
                      <v-select
                        label="Flow Direction"
                        v-model="chamber.flowDirection"
                        :items="flowDirections[chamber.orientation]"
                        :rules="[v => !!v || 'Input required']"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-numeric-text-field-unit
                        v-model="chamber.length"
                        :settings="settings.length"
                        :rules="[requiredRule]"
                        :disabled="chamber.autoDimension"
                        @change="calcSurfaceArea"
                      />
                      <v-numeric-text-field-unit
                        v-show="chamber.shape === 'cylindrical'"
                        v-model="chamber.diameter"
                        :settings="settings.diameter"
                        :rules="[requiredRule]"
                        :disabled="chamber.autoDimension"
                        @change="calcSurfaceArea"
                      />
                      <v-numeric-text-field-unit
                        v-show="chamber.shape === 'rectangular'"
                        v-model="chamber.width"
                        :settings="settings.width"
                        :rules="[requiredRule]"
                        :disabled="chamber.autoDimension"
                        @change="calcSurfaceArea"
                      />
                      <v-numeric-text-field-unit
                        v-show="chamber.shape === 'rectangular'"
                        v-model="chamber.height"
                        :settings="settings.height"
                        :rules="[requiredRule]"
                        :disabled="chamber.autoDimension"
                        @change="calcSurfaceArea"
                      />
                      <v-numeric-text-field-unit
                        v-model="chamber.wallThickness"
                        :settings="settings.wallThickness"
                        :rules="[requiredRule]"
                        @change="calcSurfaceArea"
                        :disabled="
                          chamber.heatLoss.method === 'Wall Layers'
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-switch
                        v-model="chamber.autoDimension"
                        label="Auto Dimension"
                        @change="changeAutoDimension"
                      ></v-switch>
                      <v-numeric-text-field-unit
                        v-model="chamber.residenceTime"
                        :settings="settings.residenceTime"
                        v-show="chamber.autoDimension"
                        :rules="[requiredRule]"
                        @change="calcDimensions"
                      />
                      <v-numeric-text-field-unit
                        v-show="
                          chamber.shape === 'cylindrical' &&
                            chamber.autoDimension
                        "
                        v-model="chamber.ldRatio"
                        :settings="settings.ldRatio"
                        :rules="[requiredRule]"
                        @change="calcDimensions"
                      />
                      <v-numeric-text-field-unit
                        v-show="
                          chamber.shape === 'rectangular' &&
                            chamber.autoDimension
                        "
                        v-model="chamber.lhRatio"
                        :settings="settings.lhRatio"
                        :rules="[requiredRule]"
                        @change="calcDimensions"
                      />
                      <v-numeric-text-field-unit
                        v-show="
                          chamber.shape === 'rectangular' &&
                            chamber.autoDimension
                        "
                        v-model="chamber.whRatio"
                        :settings="settings.whRatio"
                        :rules="[requiredRule]"
                        @change="calcDimensions"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Heat Losses</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-select
                        label="Heat Loss Calculation Method"
                        v-model="chamber.heatLoss.method"
                        :items="heatLossMethods"
                      />
                    </v-col>
                  </v-row>
                  <v-divider
                    v-show="chamber.heatLoss.method !== 'Adiabatic'"
                  ></v-divider>
                  <v-container
                    fluid
                    v-show="
                      chamber.heatLoss.method === 'Area Specific'
                    "
                  >
                    <v-row>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-numeric-text-field-unit
                          v-model="
                            chamber.heatLoss.areaSpecificHeatLoss
                          "
                          :settings="settings.areaSpecificHeatLoss"
                          :rules="[numericRule]"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-numeric-text-field-unit
                          v-model="heatLoss"
                          :settings="settings.heatLoss"
                          disabled
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-numeric-text-field-unit
                          v-model="chamber.surface"
                          :settings="settings.surface"
                          :disabled="chamber.autoSurface"
                        >
                          <v-icon
                            slot="prepend"
                            @click="changeAutoSurface()"
                            class="switch-unit-button"
                          >
                            {{ surfaceIcon }}
                          </v-icon>
                        </v-numeric-text-field-unit>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container
                    class="my-4"
                    fluid
                    v-show="chamber.heatLoss.method === 'Wall Layers'"
                  >
                    <v-row>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-numeric-text-field-unit
                          v-model="chamber.heatLoss.emissivityWall"
                          :settings="settings.emissivity"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-numeric-text-field-unit
                          v-model="chamber.heatLoss.alphaWall"
                          :settings="settings.alpha"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-slider
                        label="Number of Wall Layers"
                        class="align-center"
                        thumb-label="always"
                        thumb-size="32"
                        step="1"
                        max="10"
                        min="1"
                        @change="changeNumberOfWallLayers"
                        :value="chamber.heatLoss.layers.length"
                      >
                      </v-slider>
                    </v-row>
                    <v-card
                      class="pa-2"
                      v-for="(layer, index) in chamber.heatLoss
                        .layers"
                      :key="index"
                    >
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-numeric-text-field-unit
                            v-model="layer.thickness"
                            :settings="settings.layerThickness"
                            @change="changeLayerThickness"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-numeric-text-field-unit
                            v-model="layer.lambda"
                            :settings="settings.layerLambda"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- <v-divider></v-divider>
                    <v-card class="px-2 my-4">
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-numeric-text-field-unit
                            label="Total Thickness"
                            :value="layerTotalThickness"
                            :settings="settings.layerThickness"
                            disabled
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-numeric-text-field-unit
                            label="Average Thermal Conductivity"
                            :value="layerLambdaMean"
                            :settings="settings.layerLambda"
                            disabled
                          />
                        </v-col>
                      </v-row>
                    </v-card> -->
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Diagram</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <CombustionChamberDiagram :chamber="chamber" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Inlet Streams</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MixtureIterator
                    :mixtures="filteredInletMixtures"
                    :disabledMixtures="chamber.inletMixtures"
                    :readonly="true"
                    :deletable="true"
                    :checked="[]"
                    :iconColor="themeIconColor"
                    @deleteMixture="deleteMixture"
                  />
                  <AddMixturePopup
                    :mixtures="mixtures"
                    :usedMixtures="usedMixtures"
                    :iconColor="iconColor"
                    @addMixture="addMixture"
                  />

                  <v-numeric-text-field-unit
                    v-if="chamber.lambda !== null"
                    v-model="chamber.lambda"
                    :settings="settings.lambda"
                    disabled
                  >
                    <template slot="label">
                      &lambda; (without setpoint)
                    </template>
                  </v-numeric-text-field-unit>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Setpoint Control</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn @click="solve(chamber)">
                    Solve Chamber</v-btn
                  >
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-switch
                        v-model="chamber.setpointActive"
                        label="Active"
                      />
                      <v-select
                        v-show="chamber.setpointActive"
                        label="Type"
                        v-model="chamber.setpoint"
                        :items="chamber.setpoints"
                        item-text="text"
                        return-object
                        @change="calcSetpointMassFlow"
                      />
                      <v-numeric-text-field-unit
                        v-if="chamber.setpointActive"
                        v-show="chamber.setpoint.type === 'lambda'"
                        v-model="chamber.setpoints[0].value"
                        :settings="settings.lambda"
                        @change="calcSetpointMassFlow"
                      >
                        <template slot="label">
                          &lambda;
                        </template>
                      </v-numeric-text-field-unit>
                      <v-numeric-text-field-unit
                        v-if="chamber.setpointActive"
                        v-show="chamber.setpoint.type === 'T'"
                        v-model="chamber.setpoints[1].value"
                        :settings="settings.temperature"
                        @change="calcSetpointMassFlow"
                      />
                      <v-select
                        label="Stoichiometry Settings"
                        v-if="chamber.setpointActive"
                        v-show="chamber.setpoint.type === 'T'"
                        v-model="chamber.setpoints[1].stoichiometry"
                        :items="[
                          'overstoichiometric',
                          'understoichiometric',
                        ]"
                      >
                      </v-select>
                      <v-numeric-text-field
                        v-if="chamber.setpointActive"
                        v-show="chamber.setpoint.type === 'O2'"
                        v-model="o2Fraction"
                        :label="settings.o2.label"
                        :suffix="settings.o2.unit"
                        @change="calcSetpointMassFlow"
                      >
                        <v-menu
                          slot="append"
                          transition="slide-y-transition"
                          bottom
                          close-on-content-click
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="inputFractions.length > 1"
                              v-bind="attrs"
                              v-on="on"
                              class="switch-unit-button"
                            >
                              mdi-menu-down
                            </v-icon>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(fraction,
                              index) in inputFractions"
                              :key="index"
                              @click="changeInputFraction(fraction)"
                            >
                              <v-list-item-title>{{
                                fraction.unit
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-numeric-text-field>
                      <SelectCoupledMixturePopup
                        v-if="chamber.setpointActive"
                        :coupledMixture="coupledMixture"
                        :mixtures="setpointMixtures"
                        :usedMixtures="usedOrCoupledMixtures"
                        :iconColor="themeIconColor"
                        @changeCoupledMixture="changeCoupledMixture"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Outlet Streams</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-slider
                    label="Number of outlet streams"
                    v-model="numberOfOutlets"
                    class="align-center"
                    step="1"
                    max="10"
                    min="1"
                    thumb-label="always"
                  >
                  </v-slider>
                  <OutletInputs
                    v-for="(mix, index) in chamber.outletMixtures"
                    :key="index"
                    v-model="chamber.outletMixtures[index]"
                    @fractionChanged="calcChamberMassFlows"
                  >
                  </OutletInputs>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-card-text>
      </v-card>
      <LoadingOverlay
        :loading="loading.status"
        :text="loading.text"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import MixtureIterator from './MixtureIterator';
import AddMixturePopup from './AddMixturePopup';
import SelectCoupledMixturePopup from './SelectCoupledMixturePopup';
import OutletInputs from './OutletInputs';
import CombustionChamberDiagram from '@/components/combustionchambers/CombustionChamberDiagram';
import LoadingOverlay from '@/components/misc/LoadingOverlay';
import UnitConverter from '@/plugins/unitconverter';
import { rulesMixin } from '@/mixins/rulesMixin';

export default {
  name: 'CombustionChamberPopup',
  mixins: [rulesMixin],
  props: {
    inputChamber: { type: Object, required: true },
    show: { type: Boolean, default: true },
    iconColor: { type: String, default: 'white' },
    buttonClass: { type: String, default: 'transparent' },
  },
  components: {
    MixtureIterator,
    AddMixturePopup,
    SelectCoupledMixturePopup,
    OutletInputs,
    CombustionChamberDiagram,
    LoadingOverlay,
  },
  data() {
    return {
      dialog: false,
      loading: { status: false, text: '' },
      panels: [0, 1, 3, 4, 5],
      chamber: JSON.parse(JSON.stringify(this.inputChamber)),
      settings: {
        pressure: {
          label: 'Pressure (absolute)',
          type: UnitConverter.types.PRESSURE,
          min: 0.5e5,
          max: 10e5,
          baseUnit: 'Pa',
          unit: 'bar',
          precision: 2,
          units: ['Pa', 'bar', 'mbar', 'atm'],
          precisions: [0, 3, 1, 3],
        },
        diameter: {
          label: 'Diameter',
          type: UnitConverter.types.LENGTH,
          min: 0.1,
          max: null,
          baseUnit: 'm',
          unit: 'mm',
          precision: 1,
          units: ['mm', 'cm', 'm'],
          precisions: [1, 2, 3],
        },
        length: {
          label: 'Length',
          type: UnitConverter.types.LENGTH,
          min: 0.1,
          max: null,
          baseUnit: 'm',
          unit: 'm',
          precision: 3,
          units: ['mm', 'cm', 'm'],
          precisions: [1, 2, 3],
        },
        width: {
          label: 'Width',
          type: UnitConverter.types.LENGTH,
          min: 0.1,
          max: null,
          baseUnit: 'm',
          unit: 'mm',
          precision: 3,
          units: ['mm', 'cm', 'm'],
          precisions: [1, 2, 3],
        },
        height: {
          label: 'Height',
          type: UnitConverter.types.LENGTH,
          min: 0.1,
          max: null,
          baseUnit: 'm',
          unit: 'mm',
          precision: 3,
          units: ['mm', 'cm', 'm'],
          precisions: [1, 2, 3],
        },
        wallThickness: {
          label: 'Wall Thickness',
          type: UnitConverter.types.LENGTH,
          min: 0,
          max: null,
          baseUnit: 'm',
          unit: 'mm',
          precision: 1,
          units: ['mm', 'cm', 'm'],
          precisions: [0, 2, 3],
        },
        residenceTime: {
          label: 'Residence Time',
          type: UnitConverter.types.TIME,
          min: 0,
          max: null,
          baseUnit: 's',
          unit: 's',
          precision: 2,
          units: ['s'],
          precisions: [2],
        },
        ldRatio: {
          label: 'l/d Ratio',
          type: UnitConverter.types.UNITLESS,
          min: 0.1,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        lhRatio: {
          label: 'l/h Ratio',
          type: UnitConverter.types.UNITLESS,
          min: 0.1,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        whRatio: {
          label: 'w/h Ratio',
          type: UnitConverter.types.UNITLESS,
          min: 0.1,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        surface: {
          label: 'Outer Surface Area',
          type: UnitConverter.types.AREA,
          min: 0,
          max: null,
          baseUnit: 'm²',
          unit: 'm²',
          precision: 2,
          units: ['mm²', 'cm²', 'm²'],
          precisions: [0, 1, 2],
        },
        areaSpecificHeatLoss: {
          label: 'Outer Area Specific Heat Loss',
          type: UnitConverter.types.UNITLESS,
          min: null,
          max: null,
          baseUnit: 'W/m²',
          unit: 'W/m²',
          precision: 3,
          units: ['W/m²'],
          precisions: [3],
        },
        heatLoss: {
          label: 'Heat Loss',
          type: UnitConverter.types.POWER,
          min: null,
          max: null,
          baseUnit: 'W',
          unit: 'kW',
          precision: 1,
          units: ['kW', 'MW'],
          precisions: [1, 4],
        },
        layerThickness: {
          label: 'Layer Thickness',
          type: UnitConverter.types.LENGTH,
          min: 0,
          max: null,
          baseUnit: 'm',
          unit: 'mm',
          precision: 1,
          units: ['mm', 'cm', 'm'],
          precisions: [0, 2, 3],
        },
        layerLambda: {
          label: 'Thermal Conductivity',
          type: UnitConverter.types.LENGTH,
          min: 1e-3,
          max: null,
          baseUnit: 'W/(m*K)',
          unit: 'W/(m*K)',
          precision: 3,
          units: ['W/(m*K)'],
          precisions: [3],
        },
        emissivity: {
          label: 'Inner Wall Emissivity',
          type: UnitConverter.types.UNITLESS,
          min: 0.0,
          max: 1.0,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        alpha: {
          label: 'Inner Wall Heat Transfer Coefficient',
          type: UnitConverter.types.UNITLESS,
          min: 0.0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        lambda: {
          label: 'Combustion Air Ratio (&lambda;)',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        temperature: {
          label: 'Temperature',
          type: UnitConverter.types.TEMPERATURE,
          min: 223.15,
          max: null,
          baseUnit: 'K',
          unit: '°C',
          precision: 2,
          units: ['°C', 'K'],
          precisions: [2, 2],
        },
        o2: {
          label: 'Exhaust Rest O2',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 1,
          baseUnit: '',
          unit: '%vol',
          precision: 2,
          units: ['%vol', '%mass', '%mol'],
          precisions: [2, 2, 2],
        },
        numberOfOutlets: {
          label: '',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 10,
          baseUnit: '',
          unit: '',
          precision: 0,
          units: [''],
          precisions: [0],
        },
      },
    };
  },
  methods: {
    changeNumberOfWallLayers(newNumber) {
      var layers = this.chamber.heatLoss.layers;
      const oldNumber = layers.length;
      if (oldNumber != newNumber) {
        while (layers.length !== newNumber) {
          newNumber > oldNumber
            ? layers.push(this.emptyWallLayer())
            : layers.pop();
        }
      }
      this.changeLayerThickness();
    },
    changeLayerThickness() {
      this.chamber.wallThickness = this.layerTotalThickness;
    },
    solve(chamber) {
      this.loading.status = true;
      this.loading.text = 'Solving Chamber. Please wait...';
      this.$store
        .dispatch(
          'combustionChambers/solveCombustionChamber',
          chamber,
        )
        .then(response => {
          this.chamber = response.data.chamber;
          // console.log(this.chamber.setpoint);
        })
        .catch(error => {
          // console.log(error);
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading.status = false;
          this.loading.text = '';
        });
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        if (_.round(this.outletFractionSum, 6) !== 1.0) {
          reject({
            response: {
              data: {
                msg:
                  'Outlets mass flow fractions have to sum up to 100 %. Please check your inputs.',
              },
            },
          });
        } else if (
          this.chamber.setpointActive &&
          _.isEmpty(this.coupledMixture)
        ) {
          reject({
            response: {
              data: {
                msg:
                  'Setpoint control activated but no mixture is chosen',
              },
            },
          });
        } else if (!this.$refs.form.validate()) {
          reject({
            response: {
              data: {
                msg:
                  'Some inputs are missing or not correct, please check!',
              },
            },
          });
        } else {
          resolve({ data: { msg: 'Validation successful!' } });
        }
      });
    },
    updateCombustionChamber() {
      this.validateForm()
        .then(response => {
          if (this.chamber.publicId === -1) {
            this.chamber.publicId = uuidv4();
            if (this.chamber.setpointActive) {
              this.coupledMixture.coupledChamber = this.chamber.publicId;
            }
            this.$store
              .dispatch(
                'combustionChambers/addCombustionChamber',
                this.chamber,
              )
              .then(response => {
                var snack = {
                  text: response.data.msg,
                  color: 'success',
                };
                this.$store.dispatch('misc/setSnack', snack);
                this.close();
              })
              .catch(error => {
                var snack = {
                  text: error.response.data.msg,
                  color: 'error',
                };
                this.$store.dispatch('misc/setSnack', snack);
              });
          } else {
            if (this.chamber.setpointActive) {
              this.coupledMixture.coupledChamber = this.chamber.publicId;
            }
            this.$store
              .dispatch(
                'combustionChambers/updateCombustionChamber',
                this.chamber,
              )
              .then(response => {
                var snack = {
                  text: response.data.msg,
                  color: 'success',
                };
                this.$store.dispatch('misc/setSnack', snack);
                this.close();
              })
              .catch(error => {
                var snack = {
                  text: error.response.data.msg,
                  color: 'error',
                };
                this.$store.dispatch('misc/setSnack', snack);
              });
          }
        })
        .catch(error => {
          var snack;
          snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
          return;
        });
    },
    addMixture(mixtureToAdd) {
      Array.isArray(mixtureToAdd)
        ? this.chamber.inletMixtures.push(...mixtureToAdd)
        : this.chamber.inletMixtures.push(mixtureToAdd);
      this.calcChamberMassFlows();
      this.calcChamberLambda();
      if (this.chamber.setpointActive) {
        this.calcSetpointMassFlow();
      }
    },
    deleteMixture(mixtureToDelete) {
      const index = this.chamber.inletMixtures.findIndex(
        mix => mix.publicId === mixtureToDelete.publicId,
      );
      if (index !== -1) {
        this.chamber.inletMixtures.splice(index, 1);
        if (mixtureToDelete.coupledToChamber) {
          console.log('Mixture coupled to chamber deleted');
        }
        this.calcChamberLambda();
      } else {
        console.log('Mixture not found in inlet mixtures');
      }
    },
    changeCoupledMixture(mixture) {
      if (_.isEmpty(mixture)) {
        this.uncoupleMixture();
      } else {
        this.coupleMixture(mixture);
      }
    },
    coupleMixture(mixture) {
      mixture.coupledToChamber = true;
      mixture.coupledChamber = this.chamber.publicId;
      if (this.chamber.setpoint.type == 'lambda') {
        mixture.lambda = this.chamber.setpoint.value;
      }
      mixture.massFlow = 0.0;
      this.chamber.inletMixtures.push(mixture);
      this.calcSetpointMassFlow();
    },
    uncoupleMixture() {
      const index = this.chamber.inletMixtures.findIndex(
        mix => mix.coupledToChamber,
      );

      if (index !== -1) {
        var coupledMixOld = this.chamber.inletMixtures[index];
        coupledMixOld.coupledToChamber = false;
        coupledMixOld.coupledChamber = null;
        coupledMixOld.lambda = 0.0;
        this.chamber.inletMixtures.splice(index, 1);
        // remove coupling also from store mixtures
      }
    },
    changeInputFraction(fraction) {
      this.settings.o2.unit = fraction.unit;
      this.chamber.setpoint.inputFraction = fraction.name;
    },
    calcSetpointMassFlow() {
      this.$store
        .dispatch(
          'combustionChambers/calcSetpointMassFlow',
          this.chamber,
        )
        .then(response => {
          this.calcChamberMassFlows();
        })
        .catch(error => {
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        });
    },
    calcChamberMassFlows() {
      this.$store.dispatch(
        'combustionChambers/calcChamberMassFlows',
        this.chamber,
      );
    },
    calcChamberLambda() {
      this.$store
        .dispatch('combustionChambers/calcChamberLambda', {
          chamber: this.chamber,
          includeCoupledMixture: false,
        })
        .catch(error => {
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        });
    },
    changeShape() {
      if (this.chamber.autoSurface) {
        this.calcSurfaceArea();
      }
      if (this.chamber.autoDimension) {
        this.calcDimensions();
      }
    },
    changeAutoSurface() {
      this.chamber.autoSurface = !this.chamber.autoSurface;
      if (this.chamber.autoSurface) {
        this.calcSurfaceArea();
      }
    },
    calcSurfaceArea() {
      if (this.chamber.autoSurface) {
        switch (this.chamber.shape) {
          case 'cylindrical':
            this.chamber.surface =
              Math.PI *
              this.chamber.length *
              (this.chamber.diameter +
                2 * this.chamber.wallThickness);
            break;
          case 'rectangular':
            this.chamber.surface =
              2 *
              this.chamber.length *
              (this.chamber.width +
                this.chamber.height +
                4 * this.chamber.wallThickness);
            break;
          default:
            this.chamber.surface = 0.0;
            break;
        }
      }
    },
    changeAutoDimension() {
      if (this.chamber.autoDimension) {
        this.calcDimensions();
      }
    },
    calcDimensions() {
      const t = this.chamber.residenceTime;
      const mf = this.chamber.outletMixtures.reduce(
        (pv, cv) => pv + cv.massFlow,
        0,
      );
      const rho = this.chamber.outletMixtures.reduce(
        (pv, cv) => pv + (cv.massFlow * cv.rho) / mf,
        0,
      );
      if (this.chamber.autoDimension) {
        switch (this.chamber.shape) {
          case 'cylindrical':
            var ldRatio = this.chamber.ldRatio;
            var d = Math.pow(
              (4 * mf * t) / (Math.PI * rho * ldRatio),
              1 / 3,
            );
            this.chamber.diameter = d;
            this.chamber.length = ldRatio * d;
            break;
          case 'rectangular':
            var lhRatio = this.chamber.lhRatio;
            var whRatio = this.chamber.whRatio;
            var h = Math.pow(
              (mf * t) / (rho * lhRatio * whRatio),
              1 / 3,
            );
            this.chamber.height = h;
            this.chamber.width = whRatio * h;
            this.chamber.length = lhRatio * h;
            break;
          default:
            this.chamber.surface = 0.0;
            break;
        }
      }
      if (this.chamber.autoSurface) {
        this.calcSurfaceArea();
      }
    },
    updateFlowDirection() {
      this.chamber.flowDirection = this.flowDirections[
        this.chamber.orientation
      ][0];
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.chamber = JSON.parse(JSON.stringify(this.inputChamber));
      this.chamber.setpoint = this.chamber.setpoints.find(
        sp => sp.type === this.chamber.setpoint.type,
      );
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      // this.setpointActive = !this.isEmpty(this.coupledMixture);
    },
    openDialog(e) {
      e.cancelBubble = true;
      this.reset();
      this.calcChamberLambda();
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters('combustionChambers', [
      'shapes',
      'orientations',
      'flowDirections',
      'heatLossMethods',
      'emptyWallLayer',
    ]),
    ...mapGetters('mixtures', ['emptyOutlet', 'inputFractions']),
    ...mapGetters({ themeIconColor: 'iconColor' }),
    formTitle() {
      return this.chamber.publicId === -1
        ? 'New Combustion Chamber'
        : 'Edit Combustion Chamber';
    },
    formIcon() {
      return this.chamber.publicId === -1 ? 'mdi-plus' : 'mdi-pencil';
    },
    surfaceIcon() {
      return this.chamber.autoSurface ? 'mdi-lock' : 'mdi-lock-open';
    },
    outletFractionSum() {
      return this.chamber.outletMixtures.reduce(
        (pv, cv) => pv + cv.fraction,
        0,
      );
    },
    filteredInletMixtures() {
      var filteredInletMixtures = this.chamber.inletMixtures.filter(
        mix => !mix.coupledToChamber,
      );
      return filteredInletMixtures;
    },
    o2Fraction: {
      get: function() {
        return this.chamber.setpoints[2].value * 100;
      },
      set: function(newValue) {
        this.chamber.setpoints[2].value = newValue / 100;
      },
    },
    coupledMixture() {
      const index = this.chamber.inletMixtures.findIndex(
        mix => mix.coupledToChamber,
      );
      return index !== -1 ? this.chamber.inletMixtures[index] : {};
    },
    heatLoss() {
      return (
        this.chamber.surface *
        this.chamber.heatLoss.areaSpecificHeatLoss
      );
    },
    layerTotalThickness() {
      return this.chamber.heatLoss.layers.reduce(
        (a, b) => a + b.thickness,
        0,
      );
    },
    layerLambdaMean() {
      var layers = this.chamber.heatLoss.layers;
      var totalThickness = this.layerTotalThickness;
      var lambdaMean = 0.0;
      if (this.chamber.shape === 'rectangular') {
        lambdaMean =
          totalThickness /
          layers.reduce((a, b) => a + b.thickness / b.lambda, 0);
      } else if (this.chamber.shape === 'cylindrical') {
        var dIn = this.chamber.diameter;
        var dOut = dIn + 2 * layers[0].thickness;
        layers.forEach(layer => {
          // console.log(`dIn = ${dIn} m, dOut = ${dOut}`);
          lambdaMean += Math.log(dOut / dIn) / layer.lambda;
          dIn = dOut;
          dOut = dIn + 2 * layers[0].thickness;
        });
        dIn = this.chamber.diameter;
        dOut = dIn + 2 * this.layerTotalThickness;
        lambdaMean = Math.log(dOut / dIn) / lambdaMean;
      }
      return lambdaMean;
    },
    usedMixtures() {
      var usedMixtures = [];
      usedMixtures.push(...this.chamber.inletMixtures);
      this.$store.getters[
        'combustionChambers/combustionChambers'
      ].forEach(chamber => {
        if (chamber.publicId != this.chamber.publicId) {
          usedMixtures.push(...chamber.inletMixtures);
        }
      });
      return usedMixtures;
    },
    usedOrCoupledMixtures() {
      var usedOrCoupledMixtures = [];
      usedOrCoupledMixtures.push(...this.usedMixtures);
      var allMixtures = this.$store.getters['mixtures/mixtures'];
      allMixtures.forEach(mix => {
        if (mix.coupledToFuel) {
          var index = usedOrCoupledMixtures.findIndex(
            item => item.publicId === mix.publicId,
          );
          if (index === -1) {
            usedOrCoupledMixtures.push(mix);
          }
        }
      });
      return usedOrCoupledMixtures;
    },
    mixtures() {
      var mixtures = [];
      mixtures.push(...this.$store.getters['mixtures/mixtures']);
      const chambers = this.$store.getters[
        'combustionChambers/combustionChambers'
      ];
      var outlets = [];
      chambers.forEach(chamber => {
        if (chamber.publicId !== this.chamber.publicId)
          outlets.push(...chamber.outletMixtures);
      });
      outlets.push(...this.chamber.outletMixtures);
      mixtures.push(...outlets);
      return mixtures;
    },
    setpointMixtures() {
      var mixtures = [];
      mixtures.push(...this.$store.getters['mixtures/mixtures']);
      return mixtures;
    },
    numberOfOutlets: {
      get: function() {
        return this.chamber.outletMixtures.length;
      },
      set: function(newValue) {
        do {
          if (newValue > this.numberOfOutlets) {
            var newOutlet = this.emptyOutlet(this.numberOfOutlets);
            this.chamber.outletMixtures.push(newOutlet);
          } else if (newValue < this.numberOfOutlets) {
            var outlet = this.chamber.outletMixtures.pop();
            // delete from inletMixtures of other combustion chambers
            this.$store.getters[
              'combustionChambers/combustionChambers'
            ].forEach(chamber => {
              if (chamber.publicId !== this.chamber.publicId) {
                const index = chamber.inletMixtures.findIndex(
                  mix => mix.publicId === outlet.publicId,
                );
                if (index !== -1) {
                  if (
                    confirm(
                      `Outlet "${outlet.streamId}|${outlet.name}" is used in combustion Chamber "${chamber.name}" as inlet. Remove inlet?`,
                    )
                  ) {
                    chamber.inletMixtures.splice(index, 1);
                  } else {
                    this.chamber.outletMixtures.push(outlet);
                    newValue = this.chamber.outletMixtures.length;
                    // this.numberOfOutlets = newValue;
                    // console.log(this.chamber.outletMixtures.length);
                    // console.log(this.numberOfOutlets);
                    // console.log(newValue);
                  }
                }
              }
            });
            // delete from own inletMixtures
            const index = this.chamber.inletMixtures.findIndex(
              mix => mix.publicId === outlet.publicId,
            );
            if (index !== -1) {
              if (
                confirm(
                  `Outlet "${outlet.streamId}|${outlet.name}" is used in combustion Chamber "${this.chamber.name}" as inlet. Remove inlet?`,
                )
              ) {
                this.chamber.inletMixtures.splice(index, 1);
              } else {
                this.chamber.outletMixtures.push(outlet);
                newValue = this.chamber.outletMixtures.length;
                // this.numberOfOutlets = newValue;
                // console.log(this.chamber.outletMixtures.length);
                // console.log(this.numberOfOutlets);
                // console.log(newValue);
              }
            }
          }
        } while (this.numberOfOutlets !== newValue);
        this.calcChamberMassFlows();
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    'chamber.setpointActive'(val) {
      val || this.changeCoupledMixture({});
    },
  },
  mounted() {},
};
</script>

<style scoped>
.switch-unit-button {
  pointer-events: auto;
}
</style>
