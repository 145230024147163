export const rulesMixin = {
  props: {},
  watch: {},
  data() {
    return {
      numericRule: v => {
        if (
          !isNaN(parseFloat(v)) ||
          !/[+-]?\d(\.\d+)?[Ee][+-]?\d+/g.test(v)
        )
          return true;
        return 'Has to be numeric';
      },
      requiredRule: value => !!value || 'Field is required',
    };
  },
  methods: {},
  computed: {},
};
