<template>
  <div>
    <!--<v-btn v-if="!editable" @click="editable = true">Edit</v-btn>
      <span v-else>
          <v-btn @click="endEdit">End Edit</v-btn>
      </span>-->
    <!-- download diagram -->
    <v-dialog v-model="dialogDownload">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Download
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Select Data Format</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-radio-group v-model="dialogDataFormat" column>
            <v-radio label="svg" value="svg"></v-radio>
            <v-radio label="png" value="png"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="dialogFileName"
            label="File Name"
          ></v-text-field>
        </v-col>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDownload = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="downloadDiagram"
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-slider
      :label="settings.scale * 100 + '%'"
      v-model="settings.scale"
      step="0.01"
      max="1.5"
      min="0.5"
    >
    </v-slider>

    <SvgCombustionChamber
      id="svg-diagram-combustion-chamber-show-area"
      :width="diagramData.width || 2000"
      :height="diagramData.height || 1000"
      :fluid="settings.isFluid"
      :scale="settings.scale"
      :editable="editable"
      :showGrid="settings.showGrid"
      :measuringUnit="diagramData.measuringUnit"
      :background="diagramData.background"
      :nodes="diagramData.nodes"
      :labels="{
        edit: 'Edit',
        remove: 'Remove',
        link: 'New Link',
        select: 'Select',
        cancel: 'Cancel',
        copy: 'Copy',
      }"
      @editNode="onEditNode"
      @editLink="onEditLink"
      @nodeChanged="nodeChanged"
      @linkChanged="linkChanged"
    >
    </SvgCombustionChamber>
  </div>
</template>
<script>
import SvgCombustionChamber from '@/components/svg/SvgCombustionChamber';
import * as Svg from 'save-svg-as-png';
import { diagramMixin } from '@/mixins/diagramMixin';
export default {
  mixins: [diagramMixin],
  components: {
    SvgCombustionChamber,
  },
  props: {
    chamber: null,
  },
  data() {
    return {
      inlet: [
        {
          id: 1,
          x: 500,
          y: 300,
          linkOrder: [],
          connection: [],
          type: 'burner',
          shape: this.chamber.shape, //"cylindrical"//"rectangular"
          orientation: this.chamber.orientation, //"horizontal"//"vertical"
          size: {
            width:
              this.chamber.shape == 'rectangular'
                ? this.chamber.width
                : this.chamber.diameter,
            height:
              this.chamber.shape == 'rectangular'
                ? this.chamber.width
                : this.chamber.diameter,
            length: this.chamber.length,
            diameter:
              this.chamber.shape == 'cylindrical'
                ? this.chamber.diameter
                : null,
          },
          temperature: [
            { T: 2000, x: 0 },
            { T: 1600, x: 0.5 },
            { T: 900, x: 1 },
          ],
        },
      ],
    };
  },
  watch: {
    'chamber.length': function(Val) {
      // watch it
      var length = this.convertLength(Val, 'mm');
      var diameter = this.convertLength(this.chamber.diameter, 'mm');
      var width = this.convertLength(this.chamber.width, 'mm');
      var height = this.convertLength(this.chamber.height, 'mm');
      this.updateDiagramData(length, diameter, width, height);
    },
    'chamber.diameter': function(Val) {
      // watch it
      var length = this.convertLength(this.chamber.length, 'mm');
      var diameter = this.convertLength(Val, 'mm');
      var width = this.convertLength(this.chamber.width, 'mm');
      var height = this.convertLength(this.chamber.height, 'mm');
      this.updateDiagramData(length, diameter, width, height);
    },
    'chamber.width': function(Val) {
      // watch it
      var length = this.convertLength(this.chamber.length, 'mm');
      var diameter = this.convertLength(this.chamber.diameter, 'mm');
      var width = this.convertLength(Val, 'mm');
      var height = this.convertLength(this.chamber.height, 'mm');
      this.updateDiagramData(length, diameter, width, height);
    },
    'chamber.height': function(Val) {
      // watch it
      var length = this.convertLength(this.chamber.length, 'mm');
      var diameter = this.convertLength(this.chamber.diameter, 'mm');
      var width = this.convertLength(this.chamber.width, 'mm');
      var height = this.convertLength(Val, 'mm');
      this.updateDiagramData(length, diameter, width, height);
    },
    'chamber.shape': function(Val) {
      //watch ist
      var length = this.convertLength(
        this.chamber.length,
        'mm',
        this.chamber.lengthUnit,
      );
      var diameter = this.convertLength(
        this.chamber.diameter,
        'mm',
        this.chamber.diameterUnit,
      );
      var width = this.convertLength(
        this.chamber.width,
        'mm',
        this.chamber.widthUnit,
      );
      var height = this.convertLength(
        this.chamber.height,
        'mm',
        this.chamber.heightUnit,
      );
      this.updateDiagramData(length, diameter, width, height);
    },
    'chamber.orientation': function(Val) {
      //watch ist
      var length = this.convertLength(this.chamber.length, 'mm');
      var diameter = this.convertLength(this.chamber.diameter, 'mm');
      var width = this.convertLength(this.chamber.width, 'mm');
      var height = this.convertLength(this.chamber.height, 'mm');
      this.updateDiagramData(length, diameter, width, height);
    },
  },
  methods: {
    init() {
      var length = this.convertLength(this.chamber.length, 'mm');
      var diameter = this.convertLength(this.chamber.diameter, 'mm');
      var width = this.convertLength(this.chamber.width, 'mm');
      var height = this.convertLength(this.chamber.height, 'mm');
      if (this.chamber.shape == 'cylindrical') {
        if (this.chamber.orientation == 'horizontal') {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            length + (diameter * 1) / 3 + diameter * 1.5,
            diameter,
          );
        } else {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            diameter * 2.5,
            length + (diameter * 1) / 3,
          );
        }
      } else if (this.chamber.shape == 'rectangular') {
        if (this.chamber.orientation == 'horizontal') {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            length + (height * 1) / 3 + width * 1.5,
            height,
          );
        } else {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            height + width * 1.5,
            length + (height * 1) / 3,
          );
        }
      }
      this.createBurner();
    },
    updateDiagramData(length, diameter, width, height) {
      if (this.chamber.shape == 'cylindrical') {
        if (this.chamber.orientation == 'horizontal') {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            length + (diameter * 1) / 3 + diameter * 1.5,
            diameter,
          );
        } else {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            diameter * 2.5,
            length + (diameter * 1) / 3,
          );
        }
        this.diagramData.nodes[0].length =
          length * this.diagramData.measuringUnit;
        this.diagramData.nodes[0].height =
          diameter * this.diagramData.measuringUnit;
        this.diagramData.nodes[0].width =
          diameter * this.diagramData.measuringUnit;
      } else if (this.chamber.shape == 'rectangular') {
        if (this.chamber.orientation == 'horizontal') {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            length + (height * 1) / 3 + width * 1.5,
            height,
          );
        } else {
          this.diagramData.measuringUnit = this.calcMeasuringUnit(
            height + width * 1.5,
            length + (height * 1) / 3,
          );
        }
        this.diagramData.nodes[0].length =
          length * this.diagramData.measuringUnit;
        this.diagramData.nodes[0].width =
          width * this.diagramData.measuringUnit;
        this.diagramData.nodes[0].height =
          height * this.diagramData.measuringUnit;
      }
      this.diagramData.nodes[0].shape = this.chamber.shape;
      this.diagramData.nodes[0].orientation = this.chamber.orientation;
      this.diagramData.nodes[0].point.x = 0;
      this.diagramData.nodes[0].point.y = 0;
    },
    createBurner() {
      //++this.maxID;
      this.operands.push({
        id: 0,
      });
      var length = this.convertLength(this.chamber.length, 'mm');
      var diameter = this.convertLength(this.chamber.diameter, 'mm');
      var width = this.convertLength(this.chamber.width, 'mm');
      var height = this.convertLength(this.chamber.height, 'mm');

      this.diagramData.nodes.push({
        id: 0,
        diameter:
          (this.chamber.shape == 'cylindrical' ? diameter : null) *
          this.diagramData.measuringUnit,
        width:
          (this.chamber.shape == 'rectangular' ? width : diameter) *
          this.diagramData.measuringUnit,
        height:
          (this.chamber.shape == 'rectangular' ? height : diameter) *
          this.diagramData.measuringUnit,
        length: length * this.diagramData.measuringUnit,
        objectType: 'burner',
        shape: this.chamber.shape,
        orientation: this.chamber.orientation,
        content: {
          text: 'burner' + 0,
          fontSize: this.settings.style.size,
        },
        point: {
          x: 0,
          y: 0,
        },
        stops: [
          { T: 2000, x: 0 },
          { T: 1600, x: 0.5 },
          { T: 900, x: 1 },
        ],
      });
    },
    downloadDiagram() {
      this.dialogDownload = false;
      switch (this.dialogDataFormat) {
        case 'svg':
          var name;
          var href;
          var link;
          var blob;

          blob = new Blob(
            [
              document.getElementById(
                'svg-diagram-combustion-chamber-show-area',
              ).innerHTML,
            ],
            {
              type: 'image/svg+xml',
            },
          );
          href = window.URL.createObjectURL(blob);
          link = document.createElement('a');
          if (!this.dialogFileName) {
            name = 'svgDiagram.svg';
          } else {
            name = this.dialogFileName + '.svg';
          }
          link.href = href;
          link.download = name;
          link.click();
          break;
        case 'png':
          if (!this.dialogFileName) {
            Svg.saveSvgAsPng(
              document.getElementById(
                'svg-diagram-combustion-chamber',
              ),
              'pngDiagram.png',
            );
          } else {
            Svg.saveSvgAsPng(
              document.getElementById(
                'svg-diagram-combustion-chamber',
              ),
              this.dialogFileName + '.png',
            );
          }
          break;
        default:
          if (!this.dialogFileName) {
            Svg.saveSvgAsPng(
              document.getElementById(
                'svg-diagram-combustion-chamber',
              ),
              'pngDiagram.png',
            );
          } else {
            Svg.saveSvgAsPng(
              document.getElementById(
                'svg-diagram-combustion-chamber',
              ),
              this.dialogFileName + '.png',
            );
          }
          break;
      }
    },
  },
  mounted() {
    this.settings.showGrid = true;
    this.diagramData.background = this.currentUser.settings.dark
      ? '#000000'
      : '#ffffff';
    this.init();
  },
};
</script>
<style>
SvgCombustionChamber {
  position: relative;
}
</style>
