var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Download ")])]}}]),model:{value:(_vm.dialogDownload),callback:function ($$v) {_vm.dialogDownload=$$v},expression:"dialogDownload"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Data Format")]),_c('v-divider'),_c('v-card-text',[_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.dialogDataFormat),callback:function ($$v) {_vm.dialogDataFormat=$$v},expression:"dialogDataFormat"}},[_c('v-radio',{attrs:{"label":"svg","value":"svg"}}),_c('v-radio',{attrs:{"label":"png","value":"png"}})],1)],1),_c('v-divider'),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"File Name"},model:{value:(_vm.dialogFileName),callback:function ($$v) {_vm.dialogFileName=$$v},expression:"dialogFileName"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDownload = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.downloadDiagram}},[_vm._v("Download")])],1)],1)],1),_c('v-slider',{attrs:{"label":_vm.settings.scale * 100 + '%',"step":"0.01","max":"1.5","min":"0.5"},model:{value:(_vm.settings.scale),callback:function ($$v) {_vm.$set(_vm.settings, "scale", $$v)},expression:"settings.scale"}}),_c('SvgCombustionChamber',{attrs:{"id":"svg-diagram-combustion-chamber-show-area","width":_vm.diagramData.width || 2000,"height":_vm.diagramData.height || 1000,"fluid":_vm.settings.isFluid,"scale":_vm.settings.scale,"editable":_vm.editable,"showGrid":_vm.settings.showGrid,"measuringUnit":_vm.diagramData.measuringUnit,"background":_vm.diagramData.background,"nodes":_vm.diagramData.nodes,"labels":{
      edit: 'Edit',
      remove: 'Remove',
      link: 'New Link',
      select: 'Select',
      cancel: 'Cancel',
      copy: 'Copy',
    }},on:{"editNode":_vm.onEditNode,"editLink":_vm.onEditLink,"nodeChanged":_vm.nodeChanged,"linkChanged":_vm.linkChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }