<template>
  <div>
    <MixtureCard
      v-if="!isEmpty(coupledMixture)"
      :mixture="coupledMixture"
      :mixtures="[]"
      :readonly="true"
      :iconColor="iconColor"
      @click="open"
      @deleteMixture="deselectCoupledMixture"
    />
    <v-card v-else @click="open">
      <v-alert outlined class="pa-0" color="error">
        <v-card-text>Select Mixture</v-card-text>
      </v-alert>
    </v-card>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title>
            Select Coupled Mixture
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="selectCoupledMixture"
                v-on="on"
                :disabled="checked.length === 0"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> {{ saveButtonText }} </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <MixtureIterator
            :mixtures="mixtures"
            :disabledMixtures="usedMixtures"
            :readonly="true"
            :deletable="false"
            :checked="checked"
            :iconColor="iconColor"
            :switchForUnusedMixtures="true"
            @changeChecked="changeChecked"
            @disabledAction="disabledAction"
            ref="mixtureIterator"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import MixtureIterator from './MixtureIterator';
import MixtureCard from '../mixtures/MixtureCard';
export default {
  name: 'SelectCoupledMixturePopup',
  props: {
    coupledMixture: { type: Object, required: true },
    mixtures: { type: Array, required: true },
    usedMixtures: { type: Array, required: true },
    iconColor: { type: String, default: 'white' },
  },
  components: { MixtureIterator, MixtureCard },
  data() {
    return {
      loading: false,
      dialog: false,
      checked: [],
    };
  },
  methods: {
    changeChecked(publicId) {
      var index = this.checked.findIndex(item => item === publicId);
      if (index !== -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.splice(0, this.checked.length);
        this.checked.push(publicId);
      }
    },
    disabledAction() {
      var snack = {
        text: 'Stream already used or coupled',
        color: 'warning',
      };
      this.$store.dispatch('misc/setSnack', snack);
    },
    selectCoupledMixture() {
      var coupledMixture = this.selectedMixture;
      this.$emit('changeCoupledMixture', coupledMixture);
      this.close();
    },
    deselectCoupledMixture() {
      this.checked.splice(0, this.checked.length);
      this.$emit('changeCoupledMixture', {});
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.checked = [];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    // coupledMixture() {
    //   return this.checked.length > 0
    //     ? this.mixtures.find(mix => mix.publicId === this.checked[0])
    //     : null;
    // },
    selectedMixture() {
      return this.checked.length > 0
        ? this.mixtures.find(mix => mix.publicId === this.checked[0])
        : {};
    },
    saveButtonText() {
      return `Select Mixture for Coupling`;
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style></style>
